import React, {useState, useRef} from 'react';
import { Formik, Field } from 'formik';
import FlexCard from '../components/flexcard';
import CustomFlexCard from '../components/customflexcard';
import * as Yup from 'yup';
import AXIOS from '../../axios-config';
import cogoToast from 'cogo-toast';
import ReCAPTCHA from 'react-google-recaptcha';


import Spinner from '../components/spinner';
import WesternLayout from '../components/westernlayout';
import SEO from '../components/seo';

//components
import VideoBackground from '../components/videoBackground';
import settings from '../../settings';


//images
const poster = `${settings.IMAGES_BASE_URL}/images/home/poster-group.jpg`;
const groupevent = `${settings.IMAGES_BASE_URL}/images/home/group-event.jpg`;
const offclass = `${settings.IMAGES_BASE_URL}/images/home/15-off-class.jpg`;

const WesternRegionPage = () => {

  const captchaRef =  useRef();
  const [captchaToken, setCaptchaToken] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const [dealers, setDealers] = useState([
    'Arrowhead BMW ',
    'BMW North Scottdale',
    'BMW Of Henderson ',
    'BMW of Las Vegas ',
    'BMW Tucson ',
    'Chapman BMW',
    'Chapman BMW on Camelback',
    'Sandia BMW ',
    'Santa Fe BMW ',
    'BMW Encinitas ',
    'BMW Of El Cajon ',
    'BMW of Escondido',
    'BMW of Ontario ',
    'BMW of Palm Springs ',
    'BMW of Riverside ',
    'BMW of San Diego ',
    'BMW Of Vista',
    'Beverly Hills BMW',
    'McKenna BMW ',
    'New Century BMW ',
    'Nick Alexander Imports ',
    'Santa Monica BMW ',
    'South Bay BMW ',
    'BMW of Buena Park',
    'Crevier BMW ',
    'Irvine BMW ',
    'Long Beach BMW ',
    'Sterling BMW ',
    'BMW of Hawaii ',
    'BMW of Honolulu ',
    'BMW Of Maui',
    'BMW Of Monrovia ',
    'Center BMW ',
    'Century West BMW ',
    'Pacific BMW ',
    'Valencia BMW ',
    'BMW Fresno',
    'BMW of Bakersfield ',
    'BMW of San Luis Obispo',
    'BMW of Sant Maria ',
    'BMW of Visalia ',
    'BMW Santa Barbara ',
    'Bob Smith BMW ',
    'Rusnak BMW',
    'Steve Thomas BMW ',
    'Bill Pearce BMW',
    'Concord BMW ',
    'BMW of Chico',
    'BMW of Roseville',
    'BMW of Murrieta',
    'BMW of San Rafael ',
    'East Bay BMW ',
    'Niello BMW Elk Grove ',
    'Niello BMW Sacramento',
    'Valley BMW ',
    'Weatherford BMW ',
    'BMW of Fremont ',
    'BMW of Humboldt Bay ',
    'BMW of Monterey ',
    'BMW of Mountain View ',
    'BMW of San Francisco',
    'Hansel BMW of Santa Rosa ',
    'Peter Pan BMW',
    'Stevens Creek BMW',
    'BMW Northwest',
    'BMW of Anchorage ',
    'BMW of Bellvue',
    'BMW of Bend ',
    'BMW of Eugene ',
    'BMW of Salem ',
    'BMW of Spokane ',
    'BMW of Tri-Cities ',
    'BMW of Portland ',
    'BMW Seatle ',
    'Kuni BMW ',
    'Medford BMW ',
    'BMW of Denver Downtown ',
    'BMW of Idaho Falls',
    'BMW of Murray',
    'BMW of Pleasant Grove ',
    "Co's BMW Center ",
    'Gebhardt BMW ',
    'Peterson BMW of Boise ',
    'Schomp BMW of Highland Ranch',
    'Winslow BMW of Colorado Springs'
  ])

  const initialValues = {
    firstname: '',
    lastname: '',
    organization: '',
    zipcode: '',
    email: '',
    address: '',
    city: '',
    state: '',
    phone: '',
    notes: '',
    dealer: ''
  };

  const validationSchema = Yup.object().shape({
    firstname: Yup.string()
      .required('Please enter your first name')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    lastname: Yup.string()
      .required('Please enter your last name')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    organization: Yup.string()
      .required('Please enter your organization')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    city: Yup.string()
      .required('Please enter your city')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    state: Yup.string()
      .required('Please enter your state')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    zipcode: Yup.string()
      .required('Please enter your state')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    address: Yup.string()
      .required('Please enter your address')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    email: Yup.string()
      .email()
      .required('Please enter your email address')
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Your email address is incorrect'),
    phone: Yup.string()
      .required('Please enter your phone number')
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        'Please enter a valid phone number'
      )
  });

  const errorHandling = (setSubmitting) => {
    setIsLoading(false);
    setSubmitting(false);
    captchaRef.current.reset();
    setCaptchaToken("");
    window.grecaptcha.reset();
  }

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    setIsLoading(true)
    setSubmitting(true);
    const headers = {
      'captchaToken': captchaToken
    };
    if (!captchaToken) {
      cogoToast.error("You must verify the capctcha", {
        position: "top-center"
      });
      errorHandling(setSubmitting);
      return;
    }
    AXIOS.post('/api/westernregion-form', values, {headers})
      .then(() => {
        resetForm();
        errorHandling(setSubmitting);
        cogoToast.success('Your form has been submitted successfully', { position: 'top-center' });
      })
      .catch((err) => {
        errorHandling(setSubmitting);
        cogoToast.error("Your form wasn't submitted", { position: 'top-center' });
      });
  };


  return (
    <WesternLayout>
      <SEO title="BMW Performance School | Western Region" content="noindex,nofollow,noarchive,nosnippet,noodp"/>
      <VideoBackground
        headerTitle="BUSINESS MEETS PLEASURE."
        url={`${settings.IMAGES_BASE_URL}/videos/western-region.mp4`}
        poster={poster}
      />
      <section className="teambuilding">
        <div className="container">
          <div className="teambuilding__header">
            <h2>
              time to HIT THE TRACK – <br /> BMW CENTER event OFFERs
            </h2>
            <p>
            Thank you for your interest in this special offer from your BMW Center. Please complete the form<br/> below and we will get back to
            you within 24 hours with more information.
            </p>
          </div>
        </div>
      </section>
      <CustomFlexCard
        rowType="row"
        image={groupevent}
        imageAlt="Group events"
        header="Group events"
        subheader="Book a special day for you and your group"
        text="Make the day a special one for you and your group. Customized to your needs with driving events, catering options and meeting spaces, we’re fully equipped to handle a day of thrills."
        btnTxt="Fill out information below"
        btnStyle="btn-blue"
        url="#westernregion-form"
      >
      <p>
        To get started, simply fill out the form below, and a representative will contact you.
      </p>
      </CustomFlexCard><br/>
      <FlexCard
        rowType="row"
        image={offclass}
        imageAlt="A special offer for Bmw fans"
        header="A special offer for Bmw fans"
        subheader="Get 15% off any class"
        btnText="Call 888-345-4269 to book now"
        btnStyle="btn-blue"
        url="tel:888-345-4269"
      >
      <p>
        Loyal BMW enthusiasts can receive 15% off any class at the BMW Performance Center in California. Please call to book your class, and use offer code <span className="bold">21WRDP15</span>.
      </p>
      <span>*Excludes M4GT4, private instruction, race license and group events.</span>
      <br/>
      <br/>
      </FlexCard>
      <section className="getstarted">
        <div className="container">
          <div className="getstarted__header">
            <h2 id="westernregion-form">California sun, here you come</h2>
            <p>
              With its year-round warm, sunny weather and vibrant nightlife, Thermal offers something for everyone to
              enjoy. Its close proximity to numerous hotels, golf courses and resort-like Palm Springs offers a chance
              to relax. Add in a dose of adrenaline from your time on the track, and you’ll have an event like no
              other.
            </p>
            <span>All fields are mandatory to take advantage of this special offer.</span>
          </div>
          <div className="getstarted__content">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                  <div className="input">
                    <h4>Information</h4>
                    <div className="form__group">
                      <div className="input__group">
                        <label htmlFor="firstname">First Name</label>
                        <Field
                          type="text"
                          name="firstname"
                          className={touched.firstname && errors.firstname ? 'has-error' : null}
                        />
                        {errors.firstname &&
                        touched.firstname ? <span className="input-feedback">{errors.firstname}</span> : <span className="input-feedback">&nbsp;</span>}
                      </div>
                      <div className="input__group">
                        <label htmlFor="lastname">Last Name</label>
                        <Field
                          type="text"
                          name="lastname"
                          className={touched.lastname && errors.lastname ? 'has-error' : null}
                        />
                        {errors.lastname &&
                        touched.lastname ? <span className="input-feedback">{errors.lastname}</span> : <span className="input-feedback">&nbsp;</span>}
                      </div>
                    </div>
                    <div className="form__group">
                      <div className="input__group">
                        <label htmlFor="organization">Organization</label>
                        <Field
                          type="text"
                          name="organization"
                          className={touched.organization && errors.organization ? 'has-error' : null}
                        />
                        {errors.organization &&
                        touched.organization ? <span className="input-feedback">{errors.organization}</span> : <span className="input-feedback">&nbsp;</span>}
                      </div>
                      <div className="input__group">
                        <label htmlFor="email">Your Email</label>
                        <Field
                          type="email"
                          name="email"
                          className={touched.email && errors.email ? 'has-error' : null}
                        />
                        {errors.email && touched.email ? <span className="input-feedback">{errors.email}</span> : <span className="input-feedback">&nbsp;</span>}
                      </div>
                    </div>
                    <div className="input__group">
                      <label htmlFor="address">Address</label>
                      <Field
                        type="text"
                        name="address"
                        className={touched.address && errors.address ? 'has-error' : null}
                      />
                      {errors.address && touched.address ? <span className="input-feedback">{errors.address}</span> : <span className="input-feedback">&nbsp;</span>}
                    </div>
                    <div className="form__group">
                      <div className="input__group">
                        <label htmlFor="city">City</label>
                        <Field type="text" name="city" className={touched.city && errors.city ? 'has-error' : null} />
                        {errors.city && touched.city ? <span className="input-feedback">{errors.city}</span> : <span className="input-feedback">&nbsp;</span>}
                      </div>
                      <div className="input__group input--sm">
                        <label htmlFor="state">State</label>
                        <Field
                          type="text"
                          name="state"
                          className={touched.state && errors.state ? 'has-error' : null}
                        />
                        {errors.state && touched.state ? <span className="input-feedback">{errors.state}</span> : <span className="input-feedback">&nbsp;</span>}
                      </div>
                    </div>
                    <div className="form__group">
                      <div className="input__group">
                        <label htmlFor="zipcode">Zip Code</label>
                        <Field
                          type="text"
                          name="zipcode"
                          className={touched.zipcode && errors.zipcode ? 'has-error' : null}
                        />
                        {errors.zipcode &&
                        touched.zipcode ? <span className="input-feedback">{errors.zipcode}</span> : <span className="input-feedback">&nbsp;</span>}
                      </div>
                      <div className="input__group">
                        <label htmlFor="phone">Phone</label>
                        <Field
                          type="tel"
                          name="phone"
                          className={touched.phone && errors.phone ? 'has-error' : null}
                        />
                        {errors.phone && touched.phone ? <span className="input-feedback">{errors.phone}</span> : <span className="input-feedback">&nbsp;</span>}
                      </div>
                    </div>

                    <div className="input__group input--sm input__referral">
                      <label htmlFor="dealers">Referral Dealer</label>
                      <Field
                        as="select"
                        name="dealer"
                        className={touched.dealer && errors.dealer ? 'has-error' : null}
                      >
                        <option defaultValue>
                          Select Dealer
                        </option>
                        {dealers.map((dealer) => {
                          return (
                            <option value={dealer} key={dealer}>
                              {dealer}
                            </option>
                          );
                        })}
                      </Field>
                    </div>
                    <div className="form__group">
                      <div className="input__group">
                        <label htmlFor="notes">Additional Notes</label>
                        <Field as="textarea" name="notes" />
                      </div>
                    </div>
                  </div>
                  <ReCAPTCHA sitekey={settings.REACT_APP_SITE_KEY} ref={captchaRef} onChange={val => setCaptchaToken(val)} onExpired={e => setCaptchaToken("")}/>
                  <button className="btn btn-blue" type="submit" disabled={isSubmitting}>
                    {isLoading ? <Spinner /> : 'Submit'}
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </section>
    </WesternLayout>
  );
}

export default WesternRegionPage;
